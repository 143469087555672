import { useContext } from 'react';
import { Link } from 'react-router-dom';

import QConnectLogo from '../../assets/logos/QConnectLogo';
import { HeaderLeft, HeaderRight } from '../../layouts/Header/Header';
import Nav, { NavItem } from '../../widgets/Nav';
import Button, { IButtonProps } from '../../widgets/Button';
import Popovers from '../../widgets/Popovers';
import Icon from '../../wrappers/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import ThemeContext from '../../contexts/themeContext';
import ManageProfile from '../header/ManageProfile';
import InvitationPanel from './invitations/InvitationsPanel';

const HomeHeader = () => {

  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg',
  };

  return (
    <div className="container-fluid p-3 bg-secondary sticky-top">
      <div className="row">
        <div className="col-sm-12">
          <Nav
            tag="nav"
            design='pills'

            isFill={true}
            isJustified={true}
            isVertical={false}
            className='row align-items-center'>

            <HeaderLeft className='col-md-3'>
              <div className='col-md-6 col-sm-12 d-flex justify-content-center'>
                <NavItem className=''>
                  <Link to="/home" className='p-0' >
                    <QConnectLogo width={"10rem"} />
                  </Link>
                </NavItem>
              </div>
            </HeaderLeft>

            <div className="col-md-6 col-sm-12">
              <div className="row align-items-center">
                <div className='col-md-4 col-sm-12 py-2'>
                  <NavItem>
                    <Link className="fs-5" to="#">
                      {"How it works"}
                    </Link>
                  </NavItem>
                </div>
                <div className='col-md-4 col-sm-12 py-2'>
                  <NavItem className=''>
                    <Link className="fs-5" to="#" >
                      {"Explore & Learn"}
                    </Link>
                  </NavItem>
                </div>
                {/* <div className='col-md-3 col-sm-12 py-2'>
                  <NavItem className=''>
                    <Link className="fs-5" to="/manage-subscription" >
                      {"Plans"}
                    </Link>
                  </NavItem>
                </div> */}
                <div className='col-md-4 col-sm-12'>
                  <NavItem className=''>
                    <Link className="fs-5" to="#" >
                      {"Company"}
                    </Link>
                  </NavItem>
                </div>
              </div>
            </div>


            <HeaderRight className='col-md-3'>
              <div className='row g-3 justify-content-end'>

                {/* Invitations */}
                <div className='col-auto'>
                  <InvitationPanel />
                </div>

                {/* Dark Mode */}
                <div className='col-auto'>
                  <Popovers trigger='hover' desc='Dark / Light mode'>
                    <Button
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...styledBtn}
                      onClick={() => setDarkModeStatus(!darkModeStatus)}
                      className='btn-only-icon'
                      data-tour='dark-mode'>
                      <Icon
                        icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
                        color={darkModeStatus ? 'info' : 'warning'}
                        className='btn-icon'
                      />
                    </Button>
                  </Popovers>
                </div>

                {/*	Full Screen */}
                <div className='col-auto'>
                  <Popovers trigger='hover' desc='Fullscreen'>
                    <Button
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...styledBtn}
                      icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
                      onClick={() => setFullScreenStatus(!fullScreenStatus)}
                      aria-label='Toggle dark mode'
                    />
                  </Popovers>
                </div>

                {/*	Profile */}
                <div className='col-auto ms-5'>
                  <ManageProfile />
                </div>
              </div>
            </HeaderRight>
          </Nav>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
