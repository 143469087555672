import { FC } from 'react';

import QConnectBanner from '../assets/images/svg/banner.svg';
import Footer from '../components/landing/Footer';
import Header from '../components/landing/Header';
import Page from '../layouts/Page/Page';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';
import placeholder from '../assets/images/svg/placeholder.svg'

const Landing: FC = () => {
  return (
    <PageWrapper
      isProtected={false}
      title={''}
      className='p-0'>
      <Page container="fluid" className='p-0'>
        <Header />
        <div className="container-fluid landing-page">
          <div
            className="d-flex justify-content-center align-items-center w-80 vh-80"
            style={{
              backgroundImage: `url(${QConnectBanner})`,
              backgroundPosition: 'center'
            }}
          >
            <div className="d-flex text-center align-items-center">
            <img src={placeholder} alt='placeholder' className='img-fluid w-25'/>
            <h2 className="display-1 fw-bolder">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
            </div>
          </div>

          

          <div className='row justify-content-center mt-5 '>
            <h4>About software team management: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </h4>
            <h3 className='mt-5'>Question heading?</h3>  
            <h4>About software team management: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </h4>

          </div>
        </div>
        <Footer />
      </Page>
    </PageWrapper>
  );
};

export default Landing;
