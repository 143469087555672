import React, { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';

import Chart, { IChartOptions } from '../../../widgets/Chart';
import Theme from '../../../styles/theme';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import SummaryRestService from '../services/SummaryRestService';

const CodeChurnChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.summary.commitsData)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace?.repository_ids;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (repositoryIds && repositoryIds.length > 0 && timeline.startDate !== "" && timeline.endDate !== "") {
      dispatch(SummaryRestService.fetchCommitsData({
        timeline,
        repositoryIds,
      }));
    }
  }, [
    dispatch,
    repositoryIds,
    timeline
  ]);


  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: "Commits",
        data: data?.datasets?.[0] ?? [],
      }
    ],
    options: {
      chart: {
        type: 'area',
        height: 365,
        zoom: {
          enabled: true,
        },
      },
      labels: data?.labels ?? [],
      xaxis: {
        type: 'datetime',
      },
    }
  });

  useEffect(() => {
    setChartState((state) => ({
      series: [
        {
          ...(state?.series?.[0] as {} ?? {}),
          data: data?.datasets?.[0] ?? [],
        },
        {
          ...(state?.series?.[1] as {} ?? {}),
          data: data?.datasets?.[1] ?? []
        },
      ],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      }
    }));
  }, [data]);

  return (
    <div className="col-lg-12">
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader
          className=""
          style={{
            "backgroundColor": Theme.colorPalette.primary[100],
            "borderRadius": '2rem 2rem 0rem 0rem'
          }}>
          <CardLabel icon="AreaChart">
            <CardTitle>
              {"Commits"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default CodeChurnChart;
