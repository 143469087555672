import CodeChurnChart from "./charts/CodeChurnChart";
import MetricCardContainer from "./metric/MetricCardContainer";
import PageWrapper from "../../layouts/PageWrapper/PageWrapper";
import Page from "../../layouts/Page/Page";
import ActiveRepositoryChart from "./charts/ActiveRepositoryChart";
import ActiveContributorChart from "./charts/ActiveContributorChart";
import ResolvedNewPRChart from "../productivity/charts/ResolvedNewPRChart";
import CommitsPerIssueChart from "../productivity/charts/CommitsPerIssueChart";
import PRClosingTimeChart from "../productivity/charts/PRClosingTimeChart";
import BranchLeaderboardChart from "../productivity/charts/BranchLeaderboardChart";
import UserAvgTimeOnIssuesChart from "../productivity/charts/UserAvgTimeOnIssuesChart";
import IssueAvgCloseTimeChart from "./charts/IssueAvgCloseTimeChart";
import IssueAvgCloseTimeByUserChart from "./charts/IssueAvgCloseTimeByUserChart";
import CommitsChart from "./charts/CommitsChart";
import IssueOpenClosedChart from "./charts/IssueOpenClosedChart";

const Summary = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Summary'}
      className={'p-0'}>
      <Page container="fluid" className="px-5">

        <MetricCardContainer />

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <CodeChurnChart />
          </div>
          <div className="col-sm-12 col-md-6">
            <CommitsChart />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-md-4">
            <IssueOpenClosedChart />
          </div>
          <div className="col-sm-3 col-md-4">
            <IssueAvgCloseTimeChart />
          </div>
          <div className="col-sm-3 col-md-4">
            <IssueAvgCloseTimeByUserChart />
          </div>
        </div>


        <div className="row"> 
          <div className="col-sm-4 col-md-4">
              <ResolvedNewPRChart />
            </div>
            <div className="col-sm-3 col-md-4">
              <PRClosingTimeChart />
            </div>
            <div className="col-sm-3 col-md-4">
              <CommitsPerIssueChart/>
            </div>
        </div>


        <div className="row"> 
          <div className="col-sm-4 col-md-4">
              <ActiveContributorChart />
            </div>
            <div className="col-sm-3 col-md-4">
              <BranchLeaderboardChart />
            </div>
            <div className="col-sm-3 col-md-4">
              <ActiveRepositoryChart/>
            </div>
        </div>

      </Page>
    </PageWrapper>

  );
};

export default Summary;
