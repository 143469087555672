import { createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../../../libs/HttpClient';

interface FetchChartParams {
  timeline: { startDate: string; endDate: string }
  repositoryIds: string[];
  basedOn?: string;
}

// Integrated
export const fetchUserCodeChurnDataRaw = createAsyncThunk(
  'quality/userChurnData',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (await httpClient.post('/service/vcs/endpoint/commits/churn-user', {
      repositoryIds,
      startDate: startDate,
      endDate: endDate,
    })).data;
  }
);

// Integrated
export const fetchGithubContributors = createAsyncThunk(
  'githubContributor/list',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/git-users', {
        repositoryIds,
        startDate: startDate,
        endDate: endDate,
      })
    ).data;
  }
);

// Integrated
export const fetchSmellTypeDensityOverTime = createAsyncThunk(
  'quality/smellDensityOverTime',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/code-quality/endpoint/commit-smell/category-density', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

// Integrated
export const fetchSmellCategoryDetails = createAsyncThunk(
  'quality/smellCategoryDetails',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/code-quality/endpoint/commit-smell/details', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

// Integrated
export const fetchRefactoringFrequency = createAsyncThunk(
  'quality/refactoringFrequency',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/refactoring/endpoint/refactor-freq', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

// Integrated
export const fetchFileChurn = createAsyncThunk(
  'quality/fileChurn',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (await httpClient.post('/service/vcs/endpoint/committed-files/churn', {
      startDate,
      endDate,
      repositoryIds,
    })).data;
  }
);

const QualityRestService = {
  fetchUserCodeChurnDataRaw,
  fetchGithubContributors,
  fetchSmellTypeDensityOverTime,
  fetchSmellCategoryDetails,
  fetchRefactoringFrequency,
  fetchFileChurn,
};

export default QualityRestService;
