import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../widgets/Button';
import FormGroup from '../../widgets/forms/FormGroup';
import Input from '../../widgets/forms/Input';

import { toast } from 'react-toastify';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import FormGroupWrapper from '../../widgets/forms/FormGroupWrapper';
import AuthRestService from './services/AuthRestService';

const RegistrationHeader: FC = () => {
  return (
    <>
      <div className='text-center h1 fw-bold mt-5 pb-5'>Create Account</div>
    </>
  );
};

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const RegistrationForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SignUpSchema,
    validateOnChange: false,
    onSubmit: (values: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    }) => {
      dispatch(AuthRestService.registerUser(values)).then((response) => {
        const payload = response.payload;

        if (payload === null || payload === undefined) {
          toast.error('Failed to register you');
          return;
        }

        if (!payload.status) {
          toast.error('Failed to register you');
          return;
        }

        toast.success("I've registered you!");
        navigate('/login');
      });
    },
  });

  return (
    <>
      <RegistrationHeader />
      <form className='row g-4 w-75 mx-auto'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-6'>
              <FormGroupWrapper isError={!!formik.errors.firstName}>
                <FormGroup id='firstName' isFloating label='First Name'>
                  <Input
                    autoComplete='given-name'
                    value={formik.values.firstName}
                    isTouched={formik.touched.firstName}
                    invalidFeedback={formik.errors.firstName}
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </FormGroupWrapper>
            </div>
            <div className='col-6'>
              <FormGroupWrapper isError={!!formik.errors.lastName}>
                <FormGroup id='lastName' isFloating label='Last Name'>
                  <Input
                    autoComplete='family-name'
                    value={formik.values.lastName}
                    isTouched={formik.touched.lastName}
                    invalidFeedback={formik.errors.lastName}
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </FormGroupWrapper>
            </div>
          </div>
        </div>

        <FormGroupWrapper isError={!!formik.errors.email}>
          <FormGroup id='email' isFloating label='Email'>
            <Input
              type='email'
              autoComplete='email'
              value={formik.values.email}
              isTouched={formik.touched.email}
              invalidFeedback={formik.errors.email}
              isValid={formik.isValid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormGroup>
        </FormGroupWrapper>
        <FormGroupWrapper isError={!!formik.errors.password}>
          <FormGroup id='password' isFloating label='Password'>
            <Input
              type='password'
              autoComplete='password'
              value={formik.values.password}
              isTouched={formik.touched.password}
              invalidFeedback={formik.errors.password}
              isValid={formik.isValid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormGroup>
        </FormGroupWrapper>

        <div className='w-100 d-flex justify-content-center align-items-center mx-auto pb-5'>
          <Button
            buttonType='warning-btn'
            className='py-2 fs-4'
            onClick={formik.handleSubmit}>
            Sign Up
          </Button>
          <div className='d-flex w-50 justify-content-center align-items-center ms-auto'>
            <h5 className='mb-3 pe-2 pt-3 ml-5'>Already registered?</h5>

            <Button
              buttonType='read-btn'
              color='dark'
              className='py-3 fs-5'
              onClick={() => {
                navigate('/login');
              }}>
              Login
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegistrationForm;
