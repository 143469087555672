import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../widgets/Button';
import FormGroup from '../../widgets/forms/FormGroup';
import FormGroupWrapper from '../../widgets/forms/FormGroupWrapper';
import Input from '../../widgets/forms/Input';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { UserActions } from '../user/slice/UserSlice';

import { validateResponse } from '../../utils/ResponseHelpers';
import AuthRestService from './services/AuthRestService';

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    validateOnChange: false,
    onSubmit: (values: { email: string; password: string }) => {
      dispatch(AuthRestService.authenticateUser(values)).then((response) => {
        if (!validateResponse(response, 'Failed to authenticate you')) return;

        dispatch(UserActions.setToken(response.payload.data.token));
        navigate('/home');
      });
    },
  });

  return (
    <>
      <form className='mt-5'>
        <FormGroupWrapper isError={!!formik.errors.email}>
          <FormGroup id='email' isFloating label='Email' className={'w-75 mx-auto'}>
            <Input
              autoComplete='email'
              value={formik.values.email}
              isTouched={formik.touched.email}
              invalidFeedback={formik.errors.email}
              isValid={formik.isValid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => {
                formik.setErrors({});
              }}
            />
          </FormGroup>
        </FormGroupWrapper>
        <FormGroupWrapper isError={!!formik.errors.password}>
          <FormGroup id='password' isFloating label='Password' className={'w-75 mx-auto'}>
            <Input
              type='password'
              autoComplete='current-password'
              value={formik.values.password}
              isTouched={formik.touched.password}
              invalidFeedback={formik.errors.password}
              validFeedback='Looks good!'
              isValid={formik.isValid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormGroup>
        </FormGroupWrapper>
        <div className='w-75 d-flex justify-content-center align-items-center mx-auto pb-5'>
          <Button buttonType='primary-btn' className='py-2 fs-4' onClick={formik.handleSubmit}>
            Login
          </Button>
          <div className='d-flex w-50 justify-content-center align-items-center ms-auto'>
            <h5 className='mb-3 pe-2 pt-3'>New to QConnect?</h5>

            <Button
              buttonType='read-btn'
              color='dark'
              className='fs-5 py-3'
              onClick={() => {
                navigate('/register');
              }}>
              Signup
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
