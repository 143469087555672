import { FC, useCallback, useEffect, useState } from "react";
import WorkspaceRestService from "../services/WorkspaceRestService";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { validateResponse } from "../../../utils/ResponseHelpers";
import classNames from "classnames";
import Tooltips from "../../../widgets/Tooltips";
import Button from "../../../widgets/Button";
import RepositoryRestService from "../../repository/services/RepositoryRestService";
import { toast } from "react-toastify";
import moment from "moment";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../widgets/OffCanvas";
import Card, { CardBody, CardHeader } from "../../../widgets/Card";


interface IWorkspaceRepositoryAnalysis {
  repositoryId: string
}

const WorkspaceRepositoryAnalysis: FC<IWorkspaceRepositoryAnalysis> = (
  { repositoryId }
) => {
  const dispatch = useAppDispatch();

  const [analysisDetailPanelStatus, setAnalysisDetailPanelStatus] = useState(false)
  const [analysisDetails, setAnalysisDetails] = useState({
    "start_time": null,
    "end_time": null,
    "tast_status": [
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      },
      {
        name: "Loading Task...",
        status: "pending",
        start_time: null,
        end_time: null
      }
    ]
  })

  const updateWorkspaceRepositoryAnalysisProgress = useCallback(
    (repositoryId: string) => {
      dispatch(
        WorkspaceRestService.fetchRepositoryAnalysisProgress(repositoryId)
      ).then((response) => {

        if (!validateResponse(
          response,
          "Failed to fetch analysis progress",
          true
        )) return;
        const payload = response.payload

        const upadatedTaskStatus = []
        const progressData = payload.data
        const tasksMetadata = progressData.tasks

        if (progressData.report === undefined) return;

        const report = progressData.report
        const tasks = progressData.report.tasks

        if (
          tasksMetadata === undefined ||
          tasksMetadata.length === 0 ||
          tasks === undefined ||
          tasks.length === 0
        ) return;


        for (let taskMetadata of tasksMetadata) {
          taskMetadata = taskMetadata[0]

          const taskReport = tasks[taskMetadata.index]

          let status = "pending"
          if (taskReport !== undefined) {
            status = taskReport.status
          }

          status = (status === "completed") ? "completed" : (status === "pending") ? "pending" : "processing"

          upadatedTaskStatus.push({
            name: taskMetadata?.label?.[status] || taskMetadata.name,
            status: status,
            start_time: taskReport?.["start_time"],
            end_time: taskReport?.["end_time"]
          })

        }

        setAnalysisDetails({
          "start_time": report["start_time"],
          "end_time": report["end_time"],
          "tast_status": upadatedTaskStatus
        })

      })
    }, [dispatch])

  useEffect(() => {

    if (repositoryId === undefined || repositoryId === "") return;

    updateWorkspaceRepositoryAnalysisProgress(repositoryId)
    const intervalId = setInterval(() => {
      updateWorkspaceRepositoryAnalysisProgress(repositoryId)
    }, 10000)

    return () => clearInterval(intervalId);
  },
    [updateWorkspaceRepositoryAnalysisProgress, repositoryId]
  )

  const handleAnalysis = (repositoryId: string) => {
    dispatch(RepositoryRestService.startRepositoryAnalysis(
      repositoryId
    )).then((response) => {

      if (!validateResponse(response, "Failed to start analysis")) return;

      toast.success(`Started analysis for repository: ${repositoryId}`);
    });
  }

  const handleViewAnalysisDetails = () => {
    setAnalysisDetailPanelStatus(true)
  }

  return (
    <section className="h-100 w-100">

      <section className="col-12">
        <div className="h5 fw-bold">{"Analysis Details: "}</div>
      </section>
      <section className="col-12 mt-1">
        <div className='d-flex'>
          <div className="fw-bold">{"Latest analysis triggered on: "}</div>
          <div className='ms-2 text-muted'>{moment(analysisDetails["start_time"]).format("MMMM Do YYYY, h:mm:ss A")}</div>
        </div>
      </section>
      <section className="col-12">
        <div className='d-flex'>
          <div className="fw-bold">{"Latest analysis completed triggered on: "}</div>
          {
            analysisDetails["end_time"] != null && <div className='ms-2 text-muted'>{moment(analysisDetails["end_time"]).format("MMMM Do YYYY, h:mm:ss A")}</div>
          }
        </div>
      </section>
      <section className="col-12 my-4">
        <Button
          className="me-2"
          color='info'
          icon='RemoveRedEye'
          onClick={() => handleViewAnalysisDetails()}>
          <span>{"View Details for Latest Analysis"}</span>
        </Button>
        <Button
          color='primary'
          icon='Send'
          iconDirection="end"
          onClick={() => handleAnalysis(repositoryId)}>
          <span>{"Start Analysis"}</span>
        </Button>
      </section>


      <div className="mb-1">
        <span className="fw-bold"> {"Repository Analysis Report:"} </span>
      </div>
      <section className="d-flex" style={{
        "height": "15px"
      }}>
        {
          analysisDetails["tast_status"].map((task, index) => {
            return (
              <Tooltips
                title={task.name}
                key={"repository-progress-status-" + index}>
                <div

                  className={classNames({
                    "flex-grow-1 border rounded h-100": true,
                    "me-1": index === 0,
                    "ms-1": index === analysisDetails["tast_status"].length - 1,
                    "mx-1": index > 0 && index < analysisDetails["tast_status"].length - 1,
                    "bg-success": task.status === "completed",
                    "bg-warning": task.status === "processing",
                    "bg-secondary": task.status === "pending",
                  })}
                  style={{
                    width: "100%"
                  }}
                ></div>
              </Tooltips>
            )
          })
        }
      </section>

      <OffCanvas
        id='notificationCanvas'
        titleId='offcanvasExampleLabel'
        placement='end'
        isOpen={analysisDetailPanelStatus}
        setOpen={setAnalysisDetailPanelStatus}>
        <OffCanvasHeader setOpen={setAnalysisDetailPanelStatus}>
          <OffCanvasTitle id='offcanvasExampleLabel'>
            <div className="fw-bold">Latest Analysis Details</div>
          </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <section>
            {
              analysisDetails["tast_status"].map((task, index) => {
                return (
                  <Card stretch className={classNames({
                    "border": true,
                    "border-success": task.status === "completed",
                    "border-warning": task.status === "processing",
                    "border-secondary": task.status === "pending",
                  })}>
                    <CardHeader>
                      <section>
                        <span className="fw-bold">{task.name}</span>
                      </section>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <section >
                        <span className="fw-bold">{"Status: "}</span>
                        <span>{task.status.toUpperCase()}</span>
                      </section>
                      { 
                        task.start_time != null && <section>
                          <span className="fw-bold">{"Start Time: "}</span>
                          <span>{moment(task.start_time).format("MMMM Do YYYY, h:mm:ss A")}</span>
                        </section>
                      }
                      { 
                        task.end_time != null && <section>
                          <span className="fw-bold">{"End Time: "}</span>
                          <span>{moment(task.end_time).format("MMMM Do YYYY, h:mm:ss A")}</span>
                        </section>
                      }
                    </CardBody>
                  </Card>
                )
              })
            }
          </section>
        </OffCanvasBody>
      </OffCanvas>


    </section>
  )
}

export default WorkspaceRepositoryAnalysis;
