import * as Yup from 'yup';

import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import Button from '../../widgets/Button';
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from '../../widgets/Card';
import Spinner from '../../widgets/Spinner';
import FormGroup from '../../widgets/forms/FormGroup';
import FormGroupWrapper from '../../widgets/forms/FormGroupWrapper';
import Input from '../../widgets/forms/Input';
import Select from '../../widgets/forms/Select';
import WorkspaceRestService from './services/WorkspaceRestService';

import Workspace from './types/Workspace';

import { toast } from 'react-toastify';
import { validateResponse } from '../../utils/ResponseHelpers';
import Icon from '../../wrappers/Icon';
import WorkspaceAnalysisProgress from './WorkspaceAnalysisProgress';
import Badge from '../../widgets/Badge';

const CreateWorkspaceSchema = Yup.object().shape({
  name: Yup.string().required('Workspace name is required'),
  integration: Yup.string().required('Integration is required'),
});

const WorkspaceList = () => {
  const { isFetching: isFetchingWorkspaces, data: workspaces } = useAppSelector(
    (state: any) => state.workspace.list,
  );
  const { data: user, isFetching: isFetchingUser } = useAppSelector((store) => store.user.details);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(WorkspaceRestService.fetchWorkspaces());
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      integration: '',
    },
    validationSchema: CreateWorkspaceSchema,
    validateOnChange: false,
    onSubmit: (values: { name: string; integration: string }) => {
      dispatch(WorkspaceRestService.createWorkspace(values)).then((response) => {
        if (!validateResponse(response, 'Failed to create workspace')) return;

        toast.success("I've created workspace for you!");
        dispatch(WorkspaceRestService.fetchWorkspaces());
      });
    },
  });

  const handleDeleteWorkspace = (workspaceId: string) => {
    dispatch(WorkspaceRestService.deleteWorkspace(workspaceId)).then((response) => {
      const payload = response.payload;

      if (payload === null || payload === undefined) {
        toast.error('Failed to delete workspace');
        return;
      }

      if (!payload.status) {
        toast.error('Failed to delete workspace');
        return;
      }
      toast.success("I've deleted workspace for you!");
      dispatch(WorkspaceRestService.fetchWorkspaces());
    });
  };

  const handleWorkspaceSelect = (workspace: Workspace) => {
    navigate(`/workspace/${workspace.id}`, {
      state: {
        id: workspace.id,
      },
    });
  };

  const handleWorkspaceSettings = (workspace: Workspace) => {
    navigate(`/workspace/${workspace.id}/settings`, {
      state: {
        id: workspace.id,
      },
    });
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='h4 fw-bold py-3'>Workspaces</div>
        </div>

        {isFetchingWorkspaces || isFetchingUser ? (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ minHeight: '50vh' }}>
            <Spinner size={50} tag={'div'} />
          </div>
        ) : (
          <>
            <div className='col-md-4'>
              <Card stretch>
                <CardBody className='d-flex align-items-center justify-content-center'>
                  <form className='row'>
                    <FormGroupWrapper isError={!!formik.errors.name}>
                      <FormGroup id='name' isFloating label='Workspace Name' className={''}>
                        <Input
                          autoComplete='name'
                          value={formik.values.name}
                          isTouched={formik.touched.name}
                          invalidFeedback={formik.errors.name}
                          isValid={formik.isValid}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onFocus={() => {
                            formik.setErrors({});
                          }}
                        />
                      </FormGroup>
                    </FormGroupWrapper>

                    <FormGroupWrapper isError={!!formik.errors.integration}>
                      <FormGroup id='source' isFloating label='Select Integration' className={''}>
                        <Select
                          id='integration'
                          name='integration'
                          ariaLabel='Select Integration'
                          placeholder={'Select Integration'}
                          value={formik.values.integration}
                          isTouched={formik.touched.integration}
                          invalidFeedback={formik.errors.integration}
                          isValid={formik.isValid}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onFocus={() => {
                            formik.setErrors({});
                          }}
                          list={[
                            {
                              text: '-- Select Integration --',
                              value: '',
                            },
                            {
                              text: 'GitHub',
                              value: 'github',
                            },
                          ]}
                        />
                      </FormGroup>
                    </FormGroupWrapper>

                    <div className='col-12 m-0'>
                      <Button
                        buttonType='primary-btn'
                        size='lg'
                        className='w-100'
                        icon='AddCircle'
                        onClick={formik.handleSubmit}>
                        Create Workspace
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </div>
            {workspaces.map((workspace: Workspace, idx: number) => {
              const isOwner = workspace.created_by === user.id;
              return (
                <div
                  className='col-md-4'
                  key={idx}
                  onClick={() => handleWorkspaceSelect(workspace)}>
                  <Card stretch style={{ cursor: 'pointer' }}>
                    <CardHeader className='bg-transparent w-100'>
                      <CardTitle className='d-flex justify-content-space-between w-100'>
                        <div>
                          <p className='fw-bold fs-3'>{workspace.name}</p>
                        </div>

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='d-flex align-items-center'>
                            <Icon className='fw-bold ms-2' icon='CustomGithub' size={'2x'} />

                            <Badge
                              isLight
                              color={isOwner ? 'success' : 'warning'}
                              className='px-3 py-2 ms-2 w-75'
                              style={{ cursor: 'pointer' }}>
                              {isOwner ? 'OWNED' : 'SHARED'}
                            </Badge>
                          </div>
                        </div>
                      </CardTitle>
                    </CardHeader>
                    <CardBody className='pt-0 d-flex'>
                      <div className='d-grid gap-2 w-80'>
                        <div>
                          <span className='fw-bold'>{'Owned By:'}</span>
                          <span className='ms-1'>{workspace['created_by_name']}</span>
                        </div>
                        <div>
                          <span className='fw-bold'>{'Created On:'}</span>
                          <span style={{ fontWeight: 500 }}>
                            {moment(workspace['audit']['created_on']).format('LLL')}
                          </span>
                        </div>
                        <div>
                          <span className='fw-bold'>{'Last Analysis:'}</span>
                          <span className='ms-1'>
                            {moment(workspace['audit']['created_on']).format('LLL')}
                          </span>
                        </div>
                        <div>
                          <span className='fw-bold'>{'Repository Analyzed:'}</span>
                          <span className='ms-1'>{workspace.repository_ids.length}</span>
                        </div>
                        <div>
                          <span className='fw-bold'>{'Repository Pending:'}</span>
                          {/* TODO Update with actual value */}
                          <span className='ms-1'>{'NA'}</span>
                        </div>
                      </div>

                      <div className='w-20 d-grid gap-3 ms-auto'>
                        <Button
                          buttonType='primary-btn'
                          onClick={(event: any) => {
                            event.stopPropagation();
                            handleWorkspaceSettings(workspace);
                          }}>
                          <div className='d-flex align-items-center'>
                            <Icon icon='Add' size='lg'></Icon>
                            <span>{'Repository'}</span>
                          </div>
                        </Button>
                        <Button
                          buttonType='secondary-btn'
                          onClick={(event: any) => {
                            event.stopPropagation();
                            handleWorkspaceSettings(workspace);
                          }}>
                          <div className='d-flex align-items-center'>
                            <Icon icon='Add' size='lg'></Icon>
                            <span>{'Member'} </span>
                          </div>
                        </Button>
                        <Button
                          buttonType='danger-btn'
                          onClick={(event: any) => {
                            event.stopPropagation();
                            handleDeleteWorkspace(workspace.id);
                          }}>
                          <div className='d-flex align-items-center'>
                            <Icon icon='Delete' size='lg'></Icon>
                            <span> {'Delete'}</span>
                          </div>
                        </Button>
                      </div>
                    </CardBody>
                    <CardFooter className='py-0'>
                      <WorkspaceAnalysisProgress workspaceId={workspace.id} />
                    </CardFooter>
                  </Card>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default WorkspaceList;
