import { RouteObject, useRoutes } from "react-router-dom";
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import Landing from '../pages/Landing';
import Home from '../pages/Home';
import AuthLayout from '../layouts/AuthLayout';
import LoginForm from '../components/auth/LoginForm';
import RegistrationForm from '../components/auth/RegistrationForm';
import Workspace from '../pages/Workspace';
import Summary from '../components/summary/Summary';
import Quality from '../components/quality/Quality';
import Productivity from '../components/productivity/Productivity';
import AuthGuard from '../guard/AuthGuard';
import WorkspaceSettings from "../components/workspace/settings/WorkspaceSettings";
import AppLayout from '../layouts/AppLayout';
import JiraConfiguration from "../components/integrations/jira/JiraConfiguration";
import Success from "../pages/Success";
import Subscrptions from "../components/landing/menu/Subscrptions";
import Blog from "../pages/Blog";

const AppRoutes = () => {
  const routes: RouteObject[]  = [
    {
      path: "/", 
      element: <LogoOnlyLayout />,
      children: [
        {
          index: true,
          element: <Landing />
        },
        {
          path: "success",
          element: <Success />
        },
        {
          path: "plans",
          element: <Subscrptions />
        },
        {
          path: "blog",
          element: <Blog />
        }
      ]
    },
    {
      path: "/", 
      element: <AuthLayout />,
      children: [
        {
          path: "/login", 
          element: <LoginForm />
        },
        {
          path: "/register", 
          element: <RegistrationForm />
        },
      ]
    },
    {
      path: "/", 
      element: <AuthGuard />,
      children: [
        {
          path: "home",
          element: <Home />
        }
      ]
    },
    {
      path: "/workspace", 
      element: <AuthGuard />,
      children: [
        {
          path: ":id",
          element: <Workspace />,
          children: [
            {
              index: true,
              element: <Summary />
            },
            {
              path: "summary",
              element: <Summary />
            },
            {
              path: "quality",
              element: <Quality />
            },
            {
              path: "productivity",
              element: <Productivity />
            },
            {
              path: "settings",
              element: <WorkspaceSettings />
            }
          ]
        }
      ]
    },
    {
      path: "/integrations", 
      element: <AppLayout />,
      children: [
        {
          path: "jira",
          element: <JiraConfiguration />
        }
      ]
    }    
  ];

  return useRoutes(routes);
}

export default AppRoutes;
