import PageWrapper from '../../../layouts/PageWrapper/PageWrapper';
import Page from '../../../layouts/Page/Page';
import WorkspaceRepositoryList from './WorkspaceRepositoryList';
import WorkspaceMemberList from './WorkspaceMemberList';

const WorkspaceSettings = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Workspace Settings'}
      className={'p-0'}>
      <Page container="fluid" className="px-5">
        <div className='row'>
          <WorkspaceRepositoryList />
        </div>
        <div className='row'>
          <WorkspaceMemberList />
        </div>
      </Page>
    </PageWrapper>
  );
};

export default WorkspaceSettings;
