import { createSlice, current } from '@reduxjs/toolkit';

import WorkspaceRestService from '../services/WorkspaceRestService';

import Workspace from '../types/Workspace';

interface TWorkspaceState {
  list: {
    isFetching: boolean,
    data: Workspace[]
  },
  selected: {
    isFetching: boolean,
    data: Workspace,
    repositories: {
      isFetching: boolean,
      data: any[]
    },
    members: {
      isFetching: boolean,
      data: any[]
    },
    timeline: {
      startDate: string,
      endDate: string
    }
  }
}

const initialState: TWorkspaceState = {
  list: {
    isFetching: true,
    data: []
  },
  selected: {
    isFetching: true,
    data: {
      id: '',
      name: '',
      integration: '',
      created_by: '',
      created_by_name: '',
      members: [],
      repository_ids: [],
      audit: {
        created_on: '',
      }
    },
    repositories: {
      isFetching: false,
      data: []
    },
    members: {
      isFetching: false,
      data: []
    },
    timeline: {
      startDate: "",
      endDate: ""
    }
  }
};

const updateWorkspaces = (state: any, action: any) => {
  state.list.data = action.payload;
  state.list.isFetching = false;
}

const updateSelectedWorkspace = (state: any, action: any) => {
  state.selected.data = action.payload;

  const currentWorkspace = current(state).selected.data
  if (currentWorkspace.id !== state.selected.data.id){
    const now = new Date();
    state.selected.timeline = {
      startDate: new Date(now.getFullYear(), now.getMonth() - 4, now.getDate()).toISOString(),
      endDate: now.toISOString(),
    }
  }

  state.selected.isFetching = false;
}

const updateSelectedWorkspaceRepository = (state: any, action: any) => {
  state.selected.repositories.data = action.payload;
  state.selected.repositories.isFetching = false
}

const updateSelectedWorkspaceMembers = (state: any, action: any) => {
  state.selected.members.data = action.payload.items;
  state.selected.members.isFetching = false
}


export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    updateWorkspaceTimeline: (state, action) => {
      state.selected.timeline = { ...action.payload }
    }
  },
  extraReducers: builder => {
    builder.addCase(WorkspaceRestService.fetchWorkspaces.fulfilled, updateWorkspaces);
    builder.addCase(WorkspaceRestService.fetchWorkspaces.pending, state => {
      state.list.isFetching = false;
    });


    builder.addCase(WorkspaceRestService.fetchWorkspace.fulfilled, updateSelectedWorkspace);
    builder.addCase(WorkspaceRestService.fetchWorkspace.pending, state => {
      state.selected.isFetching = false;
      state.selected.repositories.data = []
      state.selected.repositories.isFetching = true
      state.selected.members.data = []
      state.selected.members.isFetching = true
    });

    builder.addCase(WorkspaceRestService.fetchWorkspaceRepository.fulfilled, updateSelectedWorkspaceRepository);
    builder.addCase(WorkspaceRestService.fetchWorkspaceRepository.pending, state => {
      state.selected.repositories.isFetching = true;
    });

    builder.addCase(WorkspaceRestService.fetchWorkspaceMembers.fulfilled, updateSelectedWorkspaceMembers);
    builder.addCase(WorkspaceRestService.fetchWorkspaceMembers.pending, state => {
      state.selected.members.isFetching = true;
    });

  },
});

export const WorkspaceActions = workspaceSlice.actions;

export default workspaceSlice;


