import Nav, { NavItem } from '../../widgets/Nav';
import { Link, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import DatePicker from '../../widgets/DatePicker';
import Button from '../../widgets/Button';
import { WorkspaceActions } from './slice/WorkspaceSlice';
import ManageProfile from '../header/ManageProfile';
import WorkspaceRestService from './services/WorkspaceRestService';
import { useEffect } from 'react';
import { validateResponse } from '../../utils/ResponseHelpers';

const WorkspaceHeader = () => {

  const { timeline, data } = useAppSelector((store) => store.workspace.selected)

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data.id) return;

    dispatch(WorkspaceRestService.fetchWorkspaceTimeline(data.id))
      .then((response: any) => {

        if (!validateResponse(response, "Failed to fetch default timeline", true)) return;

        const payload = response.payload.data;

        dispatch(WorkspaceActions.updateWorkspaceTimeline({
          startDate: payload["start_date"],
          endDate: payload["end_date"]
        }));
      })
  }, [data.id, dispatch])

  const handleDateChange = (startDate: any, endDate: any) => {
    dispatch(WorkspaceActions.updateWorkspaceTimeline({ startDate, endDate }));
  };

  const handleBack = () => {
    navigate('/home', {
      replace: true
    });
  }

  return (
    <div className="p-3 bg-secondary sticky-top">
      <div className="row">
        <div className="col-sm-12">
          <Nav
            tag="nav"
            design='pills'
            isFill={true}
            isJustified={true}
            isVertical={false}
            className='row align-items-center'>
            <div className='col-md-2 col-sm-12 d-flex justify-content-around align-items-center'>
              <div>
                <Button color="light" isLink icon="ArrowBackIos" onClick={handleBack}> Back </Button>
              </div>
              <div>
                <span className='h5 fw-bold'>{data.name}</span>
              </div>
            </div>

            <div className="col-md-5 col-sm-12 mx-auto">
              <div className="row align-items-center">
                <div className='col-md-4 col-sm-12 py-2'>
                  <NavItem>
                    <Link to="summary">
                      {"Summary"}
                    </Link>
                  </NavItem>
                </div>
                {/* <div className='col-md-3 col-sm-12 py-2'>
                  <NavItem className=''>
                    <Link to="quality" >
                      {"Quality"}
                    </Link>
                  </NavItem>
                </div> 
                <div className='col-md-4 col-sm-12 py-2'>
                  <NavItem className=''>
                    <Link to="productivity" >
                      {"Productivity"}
                    </Link>
                  </NavItem>
                </div> */}
                <div className='col-md-4 col-sm-12 py-2'>
                  <NavItem className=''>
                    <Link to="settings" >
                      {"Settings"}
                    </Link>
                  </NavItem>
                </div>
              </div>
            </div>

            <div className='col-md-2 col-sm-12 d-flex justify-content-center'>
              <DatePicker
                startDate={timeline.startDate}
                endDate={timeline.endDate}
                onChange={handleDateChange} />
            </div>

            {/*	Profile */}
            <div className='col-auto ms-2'>
              <ManageProfile />
            </div>

          </Nav>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceHeader;
