import { FC } from "react";
import Card, { CardBody } from "../../../widgets/Card";
import Icon from "../../../wrappers/Icon";
import Spinner from "../../../widgets/Spinner";

interface MetricCardPayload {
  iconValue: string;
  title: string;
  metricValue: string | number;
  change?: number;  // Add change prop
  isLoading?: boolean;
  cardColor?: string;
}

const MetricCard: FC<MetricCardPayload> = ({ iconValue, title, metricValue, change, isLoading, cardColor }) => {
  const renderChangeArrow = () => {
    if (change === undefined) return null;
    if (change > 0) {
      return <span style={{ color: 'green' }}>↑ {change.toFixed(2)}%</span>;
    }
    if (change < 0) {
      return <span style={{ color: 'red' }}>↓ {change.toFixed(2)}%</span>;
    }
    return <span style={{ color: 'black' }}>--0.00%</span>;
  };

  return (
    <Card className="px-3" style={{ backgroundColor: cardColor }}>
      <CardBody className="row">
        <div className="p-1 col-12 d-flex align-items-center justify-content-between">
          <Icon icon={iconValue} color='dark' size={"4x"} />
          <div className="">
            {isLoading ? <Spinner size={30} tag={"div"} /> :
              <h1 className="" style={{ fontSize: 'xx-large' }}>
                {metricValue === -1 ? 0 : metricValue}
              </h1>}
          </div>
        </div>
        <div className="fw-bold p-1 col-12 text-center">
          {title}
        </div>
        <div className="fw-bold p-1 col-12 text-center">
          {!isLoading && renderChangeArrow()}
        </div>
      </CardBody>
    </Card>
  );
};

export default MetricCard;
