import { useNavigate } from "react-router-dom";

import LocalStorage from '../../libs/LocalStorage';

import Avatar from "../../widgets/Avatar"
import Button from "../../widgets/Button"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../widgets/Dropdown"
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { UserActions } from "../user/slice/UserSlice";
import GenericManAvatar from "../../assets/images/svg/avatar-man.svg"

const ManageProfile = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleSignOut = () => {
        LocalStorage.removeFromStorage('token');
        dispatch(UserActions.logout())
        navigate('/');
    };

    // const handleManageSubscription = () => {
    //     navigate('/manage-subscription');
    // }

    return (
        <div className='col d-flex align-items-center cursor-pointer'>
            <Dropdown>
                <DropdownToggle hasIcon={false}>
                    <Avatar
                        src={GenericManAvatar}
                        size={48} />
                </DropdownToggle>
                <DropdownMenu>
                    {/* <DropdownItem>
                        <Button
                            icon='Loyalty'
                            onClick={handleManageSubscription}>
                            {"Manage Subscription"}
                        </Button>
                    </DropdownItem> */}
                    <DropdownItem>
                        <Button
                            icon='Logout'
                            onClick={handleSignOut}>
                            {"Sign Out"}
                        </Button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default ManageProfile;
