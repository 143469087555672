import { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Theme from '../../../styles/theme';
import ProductivityRestService from '../services/ProductivityRestService';

const UserAvgTimeOnIssuesChart: FC = () => {
  const { data, isFetching } = useAppSelector((store) => store.productivity.userAvgTimeOnIssues);
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace?.repository_ids;

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: 'days',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 365,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      stroke: {
        width: 4, 
        colors: ["#1c9776"],
      },
      fill: {
        opacity: 0.6,
        colors: ["#bfe8e1a8"],
      },
      xaxis: {
        type: 'category',
        categories: data?.labels ?? [],
      },
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (repositoryIds && repositoryIds.length > 0) {
      dispatch(ProductivityRestService.fetchUserAverageTimeOnIssues({
        timeline,
        repositoryIds,
      }));
    }
  }, [
    dispatch,
    repositoryIds,
    timeline
  ]);

  useEffect(() => {
    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series: data?.datasets.map((dataset: any, idx: number) => ({
        ...(state?.series?.[idx] as {} ?? {}),
        data: dataset,
      })) ?? [],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      }
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader style={{
          "backgroundColor": Theme.colorPalette.primary[100],
          "borderRadius": '2rem 2rem 0rem 0rem'
        }}>
          <CardLabel icon='BarChart'>
            <CardTitle>
              {"User Average Time On Issues"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching} />
        </CardBody>
      </Card>
    </div>
  );
};

export default UserAvgTimeOnIssuesChart;
