import { FC } from "react";
import PageWrapper from "../layouts/PageWrapper/PageWrapper";
import Page from "../layouts/Page/Page";
import WorkspaceList from "../components/workspace/WorkspaceList";
import IntegrationsList from "../components/integrations/IntegrationsList";

const Home: FC = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Home'}
      className='p-0'>
      <Page container="fluid" className='p-0'>
        <section className="container-fluid p-5">
          <IntegrationsList />
          <WorkspaceList />
        </section>
      </Page>
    </PageWrapper>
  );
}

export default Home;
