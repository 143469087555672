import { Link, useNavigate } from 'react-router-dom';
import QConnectLogo from '../../assets/logos/QConnectLogo';
import Button from '../../widgets/Button';
import Nav, { NavItem } from '../../widgets/Nav';

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className='container-fluid p-3 bg-secondary sticky-top'>
      <div className='row'>
        <div className='col-sm-12'>
          <Nav
            tag='nav'
            design='pills'
            isFill={true}
            isJustified={true}
            isVertical={false}
            className='row align-items-center'>
            <div className='col-md-3 pe-5 col-sm-12 d-flex justify-content-start'>
              <NavItem className='w-75 me-auto'>
                <Link to='#' className='p-0'>
                  <QConnectLogo width={'10rem'} />
                </Link>
              </NavItem>
            </div>

            <div className='col-md-9 col-sm-12 d-flex pe-5 justify-content-end'>
              
              <Button
                className='px-5 py-2'
                rounded={'pill'}
                icon='Login'
                iconDirection='end'
                buttonType='primary-btn'
                size={"lg"}
                onClick={() => navigate("/login")}>
                {"Sign In"}
              </Button>
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
