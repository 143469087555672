import * as Yup from 'yup';
import { useFormik } from "formik";
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import Button from "../../../widgets/Button";
import Card, {
  CardBody,
} from "../../../widgets/Card";
import Spinner from "../../../widgets/Spinner";
import WorkspaceRestService from '../services/WorkspaceRestService';
import FormGroupWrapper from '../../../widgets/forms/FormGroupWrapper';
import FormGroup from '../../../widgets/forms/FormGroup';
import Input from '../../../widgets/forms/Input';
import { validateResponse } from '../../../utils/ResponseHelpers';

const AddMemberSchema = Yup.object().shape({
  email: Yup.string().required('Member email address is required'),
});

const WorkspaceMemberList = () => {

  const { data: workspace, members } = useAppSelector((store) => store.workspace.selected);
  const { data: user } = useAppSelector((store) => store.user.details);

  const dispatch = useAppDispatch();

  useEffect(() => {

    if (workspace.id === "") {
      return;
    }

    dispatch(WorkspaceRestService.fetchWorkspaceMembers(workspace.id))
  }, [dispatch, workspace.id])

  const memberFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ""
    },
    validationSchema: AddMemberSchema,
    validateOnChange: false,
    onSubmit: (values: { email: string; }) => {
      dispatch(WorkspaceRestService.addWorkspaceMember(
        {
          "workspaceId": workspace.id,
          "email": values.email
        })).then((response) => {

          if (!validateResponse(response, "Failed to add member")) return;
          
          toast.success("Member added successfully");
        })
    },
  });

  const handleDeleteMember = (userId: string) => {
    dispatch(WorkspaceRestService.deleteWorkspaceMember(
      {
        workspaceId: workspace.id,
        userId
      }
    )).then((response) => {
    
      if (!validateResponse(response, "Failed to delete workspace member")) return;

      toast.success("Workspace member deleted successfully");
    });
  }

  return <div className='row'>
    <div className='col-12'>
      <div className='h4 fw-bold py-3'>Members</div>
    </div>

    {members.isFetching ?
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ "minHeight": "50vh" }}>
        <Spinner size={50} tag={"div"} />
      </div> :
      <>
        {workspace.created_by === user.id &&
          (<div className='col-md-4'>
            <Card stretch>
              <CardBody className='d-flex align-items-center justify-content-center'>
                <form className='row'>
                  <FormGroupWrapper isError={!!memberFormik.errors.email}>
                    <FormGroup
                      id='email'
                      isFloating
                      label='Member Email Address'
                      className={""}>
                      <Input
                        autoComplete='name'
                        value={memberFormik.values.email}
                        isTouched={memberFormik.touched.email}
                        invalidFeedback={memberFormik.errors.email}
                        isValid={memberFormik.isValid}
                        onChange={memberFormik.handleChange}
                        onBlur={memberFormik.handleBlur}
                        onFocus={() => { memberFormik.setErrors({}); }}
                      />
                    </FormGroup>
                  </FormGroupWrapper>
                  <div className='col-12 m-0'>
                    <Button
                      color='primary'
                      size='lg'
                      className='w-100'
                      icon='PersonAdd'
                      onClick={memberFormik.handleSubmit}>
                      Add Member
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>)
        }
        {
          members.data.map((member: any, idx: number) => (
            <div className='col-md-4' key={idx}>
              <Card stretch>
                <CardBody>
                  <section className='row gx-0 justify-content-between'>
                    <section className='col-3'>
                      <div
                        className='ratio ratio-1x1'>
                        <div
                          className={classNames(
                            'rounded-2',
                            'd-flex',
                            'align-items-center',
                            'justify-content-center',
                            'overflow-hidden',
                            'shadow',
                            'bg-primary',
                            'text-white'
                          )}>
                          <span className='fw-bold fs-1'> {member["user"]["firstname"][0].toUpperCase()} </span>
                        </div>
                      </div>
                    </section>
                    <section className='col-8 d-flex flex-column justify-content-between'>
                      <section className='row gx-0'>
                        <section className='col p-0 d-flex flex-column'>
                          <div className='h4 fw-bold'>{member["user"]["firstname"] + " " + member["user"]["lastname"]}</div>
                          <div>
                            <span className='font-weight-bold'>{"Email: "}</span>
                            <span className='text-muted'>{member["user"]["email"]}</span>
                          </div>
                          <div>
                            <span className='font-weight-bold'>{"Role: "}</span>
                            <span className='text-muted'>{member["role"]}</span>
                          </div>
                        </section>
                        <section className='col-auto d-flex justify-content-center'>
                          {member["role"] !== "OWNER" && (
                            <Button
                              color='danger'
                              icon='Delete'
                              onClick={() => handleDeleteMember(member["user"]["_id"])}>
                            </Button>
                          )}
                        </section>
                      </section>
                    </section>
                  </section>
                </CardBody>
              </Card>
            </div>
          ))
        }
      </>
    }
  </div>
}

export default WorkspaceMemberList;
