import axios from 'axios';
import LocalStorage from './LocalStorage';
import store from '../store';
import { UserActions } from '../components/user/slice/UserSlice';

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

HttpClient.interceptors.request.use(config => {
  const token = LocalStorage.getFromStorage('token');
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

HttpClient.interceptors.response.use(
  config => config,
  error => {

    if (error.response.status === 401) {
      LocalStorage.removeFromStorage('token');
      store.dispatch(UserActions.logout());
    }

    return Promise.reject(error.response.data);
  }
);

export default HttpClient;
