import { FC, useCallback, useEffect, useState } from "react";
import WorkspaceRestService from "./services/WorkspaceRestService";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { validateResponse } from "../../utils/ResponseHelpers";
import classNames from "classnames";
import Tooltips from "../../widgets/Tooltips";


interface IWorkspaceAnalysisProgress {
  workspaceId: string
}

const WorkspaceAnalysisProgress: FC<IWorkspaceAnalysisProgress> = ({ workspaceId }) => {
  const dispatch = useAppDispatch();

  const [taskStatus, setTaskStatus] = useState([
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    },
    {
      name: "Loading Task...",
      status: "pending"
    }
  ])

    
  const updateWorkspaceAnalysisProgress = useCallback(
    (workspaceId: string) => {
      dispatch(
        WorkspaceRestService.fetchWorkspaceAnalysisProgress(workspaceId)
      ).then((response) => {

        if (!validateResponse(
          response,
          "Failed to fetch analysis progress",
          true
        )) return;

        const payload = response.payload

        const upadatedTaskStatus = []
        const progressData = payload.data
        const tasks = progressData.tasks
        const reports = progressData.reports

        if (
          tasks === undefined ||
          tasks.length === 0 ||
          reports === undefined ||
          reports.length === 0
        ) return;

        for (let task of tasks) {
          task = task[0]

          const overallStatus = (
            reports
              .map((report: any) => {
                report = report.tasks[task.index]
                if (report === undefined) return 0
                return report.status === "completed" ? 1 : 0.5
              })
              .reduce((accumulator: number, current: number) => accumulator + current, 0)
          )

          let status = overallStatus > 0 ? (reports.length === overallStatus ? "completed" : "processing") : "pending"

          upadatedTaskStatus.push({
            name: task?.label?.[status] || task.name,
            status: status
          })

        }
        setTaskStatus(upadatedTaskStatus)

      });
  }, [dispatch])

  useEffect(() => {

    if (workspaceId === undefined || workspaceId === "") return;

    updateWorkspaceAnalysisProgress(workspaceId);
    const intervalId = setInterval(() => {
      updateWorkspaceAnalysisProgress(workspaceId);
    }, 10000)
  
    return () => clearInterval(intervalId);
  }, [updateWorkspaceAnalysisProgress, workspaceId])

  return (
    <section className="h-100 w-100">
      <div className="mb-1">
        <span> {"Workspace Analysis Report:"} </span>
      </div>
      <section className="d-flex" style={{
        "height": "15px"
      }}>
        {
          taskStatus.map((task, index) => {
            return (
              <Tooltips 
                title={task.name}
                key={"workspace-progress-status-"+index}>
                <div
                  className={classNames({
                    "flex-grow-1 border rounded h-100": true,
                    "me-1": index === 0,
                    "ms-1": index === taskStatus.length-1,
                    "mx-1": index > 0 && index < taskStatus.length-1,
                    "bg-success": task.status === "completed",
                    "bg-warning": task.status === "processing",
                    "bg-secondary": task.status === "pending",
                  })}
                  style={{
                    width: "100%"
                  }}
                ></div>
              </Tooltips>
            )
          })
        }
      </section>
    </section>
  )
}

export default WorkspaceAnalysisProgress;
