import MetricCard from './MetricCard';
import SummaryRestService from '../services/SummaryRestService';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';

const MetricCardContainer = () => {
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace?.repository_ids || [];

  const dispatch = useAppDispatch();

  const { value: reposAdded, isFetching: isFetchingReposAdded } = useAppSelector((store) => store.summary.addedRepos);
  const { value: acSum, isFetching: isFetchingAcSum } = useAppSelector((store) => store.summary.activeContributorSum);
  const { value: commitSum, isFetching: isFetchingCommitSum } = useAppSelector((store) => store.summary.commitSum);
  const { value: churnSum, isFetching: isFetchingChurnSum } = useAppSelector((store) => store.summary.churnSum);
  const { value: prSum, isFetching: isFetchingPrSum } = useAppSelector((store) => store.summary.prSum);

  const { value: prevReposAdded } = useAppSelector((store) => store.summary.prevAddedRepos);
  const { value: prevAcSum } = useAppSelector((store) => store.summary.prevActiveContributorSum);
  const { value: prevCommitSum } = useAppSelector((store) => store.summary.prevCommitSum);
  const { value: prevChurnSum } = useAppSelector((store) => store.summary.prevChurnSum);
  const { value: prevPrSum } = useAppSelector((store) => store.summary.prevPrSum);
  
  useEffect(() => {
    if (repositoryIds.length > 0 && timeline.startDate !== "" && timeline.endDate !== "") {
      dispatch(SummaryRestService.fetchRepositoriesAdded({ timeline, repositoryIds }));
      dispatch(SummaryRestService.fetchActiveContributorSum({ timeline, repositoryIds }));
      dispatch(SummaryRestService.fetchCommitSum({ timeline, repositoryIds }));
      dispatch(SummaryRestService.fetchChurnSum({ timeline, repositoryIds }));
      dispatch(SummaryRestService.fetchPrSum({ timeline, repositoryIds }));

      if (timeline.startDate && timeline.endDate) {
        const prevTimeline = {
          startDate: new Date(
            new Date(timeline.startDate).getTime() -
              (new Date(timeline.endDate).getTime() - new Date(timeline.startDate).getTime()),
          )
            .toISOString()
            .split('T')[0],
          endDate: timeline.startDate,
        };

        dispatch(
          SummaryRestService.fetchPrevRepositoriesAdded({ timeline: prevTimeline, repositoryIds }),
        );
        dispatch(
          SummaryRestService.fetchPrevActiveContributorSum({
            timeline: prevTimeline,
            repositoryIds,
          }),
        );
        dispatch(SummaryRestService.fetchPrevCommitSum({ timeline: prevTimeline, repositoryIds }));
        dispatch(SummaryRestService.fetchPrevChurnSum({ timeline: prevTimeline, repositoryIds }));
        dispatch(SummaryRestService.fetchPrevPrSum({ timeline: prevTimeline, repositoryIds }));
      }

    }
  }, [dispatch, timeline, repositoryIds]);

  const calculateChange = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    if (isNaN(current) || isNaN(previous)) return 0;
    return ((current - previous) / previous) * 100;
  };

  const changes = {
    churnSumChange: calculateChange(churnSum ?? 0, prevChurnSum ?? 0),
    acSumChange: calculateChange(acSum ?? 0, prevAcSum ?? 0),
    reposAddedChange: calculateChange(reposAdded ?? 0, prevReposAdded ?? 0),
    commitSumChange: calculateChange(commitSum ?? 0, prevCommitSum ?? 0),
    prSumChange: calculateChange(prSum ?? 0, prevPrSum ?? 0)
  };

  return (
    <>
      <div className='row justify-content-around mb-5'>
        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue='Code'
            title='CHURN (LOC)'
            metricValue={churnSum ?? 0}
            change={changes?.churnSumChange}
            cardColor='#FFF4DE'
            isLoading={isFetchingChurnSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue='Groups'
            title='ACTIVE CONTRIBUTORS'
            metricValue={acSum ?? 0}
            change={changes?.acSumChange}
            cardColor='#E3FFDE'
            isLoading={isFetchingAcSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue='GpsFixed'
            title='REPOSITORIES'
            metricValue={reposAdded ?? 0}
            change={changes?.reposAddedChange}
            cardColor='#DDF9F6'
            isLoading={isFetchingReposAdded}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue='GpsFixed'
            title='ACTIVE PRs'
            metricValue={reposAdded ?? 0}
            change={changes?.prSumChange}
            cardColor='#EADDCA'
            isLoading={isFetchingPrSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue='AccountTree'
            title='TOTAL COMMITS'
            metricValue={commitSum ?? 0}
            change={changes?.commitSumChange}
            cardColor='#E7DEFF'
            isLoading={isFetchingCommitSum}
          />
        </div>
      </div>
    </>
  );
};

export default MetricCardContainer;
