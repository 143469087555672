import { FC } from "react";
import PageWrapper from "../layouts/PageWrapper/PageWrapper";
import Page from "../layouts/Page/Page";
import SelectedWorkspace from "../components/workspace/SelectedWorkspace";

interface IWorkspace { }

const Workspace: FC<IWorkspace> = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Home'}
      className='p-0'>
      <Page container="fluid" className='p-0'>
        <SelectedWorkspace />
      </Page>
    </PageWrapper>
  );
}

Workspace.propTypes = {};
Workspace.defaultProps = {};

export default Workspace;

