import { createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from './../../../libs/HttpClient';

interface FetchChartParams {
  timeline: { startDate: string; endDate: string }
  repositoryIds: string[];
  basedOn?: string;
  period?: string;
}

// Integrated
const fetchCodeChurnData = createAsyncThunk(
  'summary/churnData',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;

    return (
      await httpClient.post('/service/vcs/endpoint/commits/churn', {
        repositoryIds,
        startDate: startDate,
        endDate: endDate,
      })
    ).data;
  }
);

// Integrated
const fetchCommitsData = createAsyncThunk(
  'summary/commits',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;

    return (
      await httpClient.post('/service/vcs/endpoint/commits', {
        repositoryIds,
        startDate: startDate,
        endDate: endDate,
      })
    ).data;
  }
);

const fetchRepositoriesAdded = createAsyncThunk(
  'summary/addedRepos',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/repository-added', {
        startDate,
        endDate,
        repositoryIds
      })
    ).data;
  }
);

const fetchActiveContributorSum = createAsyncThunk(
  'summary/activeContributorSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/git-users/active-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchCommitSum = createAsyncThunk(
  'summary/commitSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/commits-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchChurnSum = createAsyncThunk(
  'summary/churnSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/commits/churn-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchAggSmellDensity = createAsyncThunk(
  'summary/aggSmellDensity',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/code-quality/endpoint/aggregate-smell-density', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchRefactoringAndSmellsData = createAsyncThunk(
  'summary/refactoringAndSmellsData',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/refactoring/endpoint/refactor-count', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchPrSum = createAsyncThunk(
  'summary/prSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('service/vcs/endpoint/pull-requests/active-sum', { 
        startDate,
        endDate,
        repositoryIds
      })
    ).data;
  }
);

const fetchMostActiveContributor = createAsyncThunk(
  'summary/mostActiveContributor',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/git-users/most-active', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchRepositoryLeaderboard = createAsyncThunk(
  'summary/mostActiveRepository',
  async ({ timeline, basedOn, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;

    return (
      await httpClient.post('/service/vcs/endpoint/repository-leaderboard', {
        repositoryIds,
        startDate: startDate,
        endDate: endDate,
        basedOn,
      })
    ).data.map((itr: any) => {
      return {
        id: itr.id,
        title: itr.name,
        organizaion: itr.owner,
        unit: basedOn === 'commit' ? '' : '',
        avatarUrl: itr.avatarUrl,
        value:
          basedOn === 'commit'
            ? itr.no_commits
            : new Date(itr.last_updated_on).toLocaleDateString(),
      };
    });
  }
);

const fetchHistoricalData = createAsyncThunk(
  'summary/historicalData',
  async ({ timeline, repositoryIds, period = '4w' }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/historical-data', {
        repositoryIds,
        startDate,
        endDate,
        period,
      })
    ).data;
  }
);

const fetchPrevRepositoriesAdded = createAsyncThunk(
  'summary/prevAddedRepos',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/repository-added', {
        startDate,
        endDate,
        repositoryIds
      })
    ).data;
  }
);

const fetchPrevActiveContributorSum = createAsyncThunk(
  'summary/prevActiveContributorSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/git-users/active-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchPrevCommitSum = createAsyncThunk(
  'summary/prevCommitSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/commits-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchPrevPrSum = createAsyncThunk(
  'summary/prevPrSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('service/vcs/endpoint/pull-requests/active-sum', { 
        startDate,
        endDate,
        repositoryIds
      })
    ).data;
  }
);

const fetchPrevChurnSum = createAsyncThunk(
  'summary/prevChurnSum',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/vcs/endpoint/commits/churn-sum', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchIssuesOpenClosedData = createAsyncThunk(
  'summary/issueOpenClose',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/issue-tracker/endpoint/github-issues/get-total-issues', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchIssuesAvgCloseTimeData = createAsyncThunk(
  'summary/issueAvgCloseTime',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/issue-tracker/endpoint/github-issues/get-average-closing-time', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

const fetchIssueAvgCloseTimeByUserData = createAsyncThunk(
  'summary/issueUserAvgCloseTime',
  async ({ timeline, repositoryIds }: FetchChartParams) => {
    const { startDate, endDate } = timeline;
    return (
      await httpClient.post('/service/issue-tracker/endpoint/github-issues/get-average-closing-time-by-user', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);


const SummaryRestService = {
  fetchCodeChurnData,
  fetchCommitsData,
  fetchRepositoriesAdded,
  fetchActiveContributorSum,
  fetchCommitSum,
  fetchChurnSum,
  fetchMostActiveContributor,
  fetchRefactoringAndSmellsData,
  fetchAggSmellDensity,
  fetchRepositoryLeaderboard,
  fetchHistoricalData,
  fetchPrSum,
  fetchPrevRepositoriesAdded,
  fetchPrevActiveContributorSum,
  fetchPrevCommitSum,
  fetchPrevChurnSum,
  fetchPrevPrSum,
  fetchIssuesOpenClosedData,
  fetchIssuesAvgCloseTimeData,
  fetchIssueAvgCloseTimeByUserData
};

export default SummaryRestService;
