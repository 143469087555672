import * as Yup from 'yup';
import React, { FC, useEffect, useState } from 'react';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import Button from "../../../widgets/Button";
import Card, {
  CardBody,
  CardFooter,
} from "../../../widgets/Card";
import Spinner from "../../../widgets/Spinner";
import FormGroup from '../../../widgets/forms/FormGroup';
import FormGroupWrapper from '../../../widgets/forms/FormGroupWrapper';
import Select from '../../../widgets/forms/Select';
import WorkspaceRestService from '../services/WorkspaceRestService';
import RepositoryRestService from '../../repository/services/RepositoryRestService';
import WorkspaceRepositoryAnalysis from './WorkspaceRepositoryAnalysis';
import { validateResponse } from '../../../utils/ResponseHelpers';

const AddRepositorySchema = Yup.object().shape({
  repositoryId: Yup.string().required('Repository name is required'),
});

const WorkspaceRepositoryList: FC = () => {

  const { data: workspace, repositories } = useAppSelector((store) => store.workspace.selected);
  const { data: user } = useAppSelector((store) => store.user.details);
  const [allRepositoriesState, setAllRepositoriesState] = useState([
    {
      "text": "Select Repository",
      "value": ""
    }
  ]);

  const dispatch = useAppDispatch();
  const isOwner = workspace.created_by === user.id;

  useEffect(() => {

    if (workspace.integration === "") {
      return;
    }

    dispatch(RepositoryRestService.fetchRepositories(workspace.integration))
      .then((response) => {

        if (!validateResponse(response, "Failed to fetch repositories")) return;

        const payload = response.payload;

        setAllRepositoriesState(() => [
          {
            "text": "Select Repository",
            "value": ""
          },
          ...payload.items.map((repository: any) => ({
            "text": repository["name"],
            "value": repository["id"]
          }))
        ]);
      });
  }, [dispatch, workspace.integration])


  useEffect(() => {
    if (workspace.id === "") {
      return;
    }

    dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));
  }, [dispatch, workspace.id]);

  const repositoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      repositoryId: ""
    },
    validationSchema: AddRepositorySchema,
    validateOnChange: false,
    onSubmit: async (values: { repositoryId: string; }) => {
      await handleAddRepository(values.repositoryId);
    }
  });

  const handleAddRepository = async (repositoryId: string) => {

    try {
      const response = await dispatch(WorkspaceRestService.addWorkspaceRepository({
        workspaceId: workspace.id,
        repositoryId: repositoryId
      }));

      if (!validateResponse(response, "Failed to add repository")) return;

      toast.success("Repository added successfully");
      dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));

    } catch (error: any) {
      toast.error("Failed to add repository");
      return;
    }


  }

  const handleDeleteRepository = (repositoryId: string) => {
    dispatch(WorkspaceRestService.deleteWorkspaceRepository(
      {
        workspaceId: workspace.id,
        repositoryId
      })).then((response) => {
        const payload = response.payload;

        if (payload === null || payload === undefined) {
          toast.error("Failed to delete repository");
          return;
        }

        if (!payload.status) {
          toast.error("Failed to delete repository");
          return;
        }

        toast.success("Repository deleted successfully");

        dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));
      });
  }



  return <div className='row'>
    <div className='col-12'>
      <div className='h4 fw-bold py-3'>Repositories</div>
    </div>

    {repositories.isFetching ?
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ "minHeight": "50vh" }}>
        <Spinner size={50} tag={"div"} />
      </div> :
      <>
        {
          isOwner &&
          (<div className='col-md-4'>
            <Card stretch>
              <CardBody className='d-flex align-items-center justify-content-center'>
                <form className='row'>
                  <FormGroupWrapper isError={!!repositoryFormik.errors.repositoryId}>
                    <FormGroup
                      id='repositoryId'
                      isFloating
                      label="Select Repository"
                      className={""}>
                      <Select
                        id="repositoryId"
                        name="repositoryId"
                        ariaLabel="Select Repository"
                        placeholder={"Select Repository"}
                        value={repositoryFormik.values.repositoryId}
                        isTouched={repositoryFormik.touched.repositoryId}
                        invalidFeedback={repositoryFormik.errors.repositoryId}
                        isValid={repositoryFormik.isValid}
                        onChange={repositoryFormik.handleChange}
                        onBlur={repositoryFormik.handleBlur}
                        onFocus={() => { repositoryFormik.setErrors({}); }}
                        list={allRepositoriesState}
                      />
                    </FormGroup>
                  </FormGroupWrapper>
                  <div className='col-12 m-0'>
                    <Button
                      color='primary'
                      size='lg'
                      className='w-100'
                      icon='AccountTree'
                      onClick={repositoryFormik.handleSubmit}>
                      Add Repository
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>)
        }
        {
          repositories.data.map((repository: any, idx: number) => (
            <div className='col-md-4' key={idx}>
              <Card stretch>
                <CardBody>
                  <section className='row gx-0 justify-content-between'>
                    <section className='col-2'>
                      <div
                        className='ratio ratio-1x1'>
                        <div
                          className={classNames(
                            'rounded-2',
                            'd-flex',
                            'align-items-center',
                            'justify-content-center',
                            'overflow-hidden',
                            'shadow',
                            'bg-primary',
                            'text-white'
                          )}>
                          <span className='fw-bold fs-1'>
                            {repository["name"][0].toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </section>
                    <section className='col-9 d-flex flex-column justify-content-between'>
                      <section className='row gx-0'>
                        <section className='col p-0 d-flex flex-column'>
                          <div className='h4 fw-bold'>{repository["name"]}</div>
                          <div className='text-muted'>{repository["details"]["owner"]}</div>
                        </section>
                        <section className='col-auto d-flex justify-content-center'>
                          {
                            isOwner &&
                            (<Button
                              color='danger'
                              icon='Delete'
                              onClick={() => handleDeleteRepository(repository.id)}>
                            </Button>)
                          }
                        </section>
                      </section>

                    </section>

                  </section>
                </CardBody>
                <CardFooter className='row gx-0 pt-1'>
                  <section className="col-12">
                    <WorkspaceRepositoryAnalysis repositoryId={repository.id} />
                  </section>
                </CardFooter>
              </Card>
            </div>
          ))
        }
      </>
    }
  </div>
}

export default WorkspaceRepositoryList;
